<template>
<div class="blog">
  <div style="margin-top:70px; margin-bottom:60px">
    <b-container>
      <b-row align-h="center">
        <b-col class="mx-2" md>
        </b-col>
      </b-row>
      <b-row align-h="center">
        <b-col md="1" />
        <b-col class="mx-2" md>
          <b-row v-for="post in posts" v-bind:key="post.guid" class="dashboard-container p-5 mb-3" align-h="center">
            <b-col>
                <b-row align-h="center">
                  <a class="link" :href="post.link" target="_blank">
                    <h4>{{ post.title }}</h4>
                  </a>
                </b-row>
                <b-row align-h="center">
                  <h6>{{ post.author }} - {{ post.pubDate.split(" ")[0] }}</h6>
                </b-row>
                <b-row align-h="center">
                  <div class="post-description" v-html='formatPostDescription(post.description)'></div>
                </b-row>
                <b-row align-h="center">
                  <b-badge class="m-1" v-for="keyword in post.categories" v-bind:key="keyword"> {{keyword}} </b-badge>
                </b-row>
            </b-col>
          </b-row>
          <b-row class="dashboard-container p-3 mb-3" align-v="center" align-h="center">
            <b-col>
                <b-row align-h="center">
                  <a class="link" href="https://invictastake.medium.com/" target="_blank">
                    <h6>More posts in the making ...</h6>
                  </a>
                </b-row>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="1" />
      </b-row>
    </b-container>
  </div>
</div>
</template>
<script>
import Vue from 'vue'
import {
  BRow,
  BCol,
  BContainer,
  BBadge
} from 'bootstrap-vue'
export default Vue.extend({
  name: 'Blog',
  components: {
    BRow,
    BCol,
    BContainer,
    BBadge
  },
  data () {
    return {
      posts: []
    }
  },
  created () {
    this.posts = this.$store.state.blog.posts
  },
  methods: {
    formatPostDescription (value) {
      return value.replace(/<img[^>]*>/g, '').slice(0, 250) + '...'
    }
  }
})
</script>
